// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

.hidden {
  display: none;
}

.critical {
  color: red;
}

.criticalLabel {
  color: #f04953;
}

.layer-close-button {
  right: -47px !important;
}

.custom-tiers-editor {
  max-width: 960px;

  .group-match-control {
    padding: 8px 0 8px 24px;
    margin-left: -1px;
    border: 2px solid rgba(0, 0, 0, 0.15);
  }

  .custom-tiers-editor-rule {
    position: relative;
    margin-left: 24px;
    margin-bottom: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    &.tree-branch {
      &::before,
      &::after {
        left: -14px !important;
      }
    }
  }

  .tree-branch {
    position: relative;
    overflow: visible;
    &::before {
      top: -12px;
      height: calc(50% + 12px);
      border-width: 0 0 2px 2px;
      content: "";
      position: absolute;
      left: 11px;
      width: 12px;
      border-color: #ccc;
      border-style: solid;
    }
    &:not(:last-of-type)::after {
      bottom: -12px;
      height: calc(50% + 12px);
      border-width: 0 0 0 2px;
      content: "";
      position: absolute;
      left: 11px;
      width: 12px;
      border-color: #ccc;
      border-style: solid;
    }
  }
}

.data-grid-empty-container {
  display: flex; /* or inline-flex */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;

  .data-grid-empty-item {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
  }
}

.react-grid-Cell > div > div > span > div.data-grid-cell-current {
  background-color: #daeef9;
}

.data-grid-cell-container {
  text-align: right;

  .data-grid-cell-item-no-change {
    color: #666 !important;
  }

  .data-grid-cell-item-up {
    color: #01a982 !important;
  }

  .data-grid-cell-item-down {
    color: #f04953 !important;
  }
}

.capacity-planning-chart {
  .grommetux-chart-axis:not(.grommetux-chart-axis--vertical) {
    .grommetux-chart-axis__slot {
      span {
        position: absolute;
      }
    }
  }
}

.file-upload-target {
  border: 2px dashed #01a982;
  padding: 16px;
  &.file-upload-target-active {
    box-shadow: 0 0 0 2px #01a982;
    border-style: solid;
  }
}

.side-nav-badge {
  overflow: hidden;

  &.count-zero {
    opacity: 0.4;
  }
}

*:not(nav) > .grommetux-anchor--disabled {
  opacity: 1;
  text-decoration: none;
}

.grommetux-meter.condensed {
  height: 28px;
  margin-top: -18px;
}

.customer-promote-table {
  min-height: 190px;
  td {
    padding: 5px 6px;
  }
}

.promote-table {
  .secondary {
    min-width: 175px !important;
    width: 175px !important;
  }
}

.customer-tenant-table {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  td {
    padding: 2px 3px;
  }

  .secondary {
    min-width: 175px !important;
    max-width: 175px !important;
    width: 175px !important;
  }

  .grommetux-table__table {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

.spinning-small {
  height: 18px;
  width: 18px;
}

.reactTooltipTheme {
  font-size: 16px !important;
  max-width: 350px !important;

  left: -100px;
}

.__react_component_tooltip.type-error {
  background-color: #f04953 !important;
}

.dotted-tooltip {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
  text-decoration: none;
}

.dotted-tooltip-radio > span:last-of-type {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
  text-decoration: none;
}

.error-row {
  text-align: center !important;
  font-size: 18px;
  font-weight: 500;
}
.disabled {
  opacity: 0.4;
}
.sort-order-options {
  border-left: 1px solid #ccc;
  background: #f5f5f5;
  .grommetux-form-field {
    background: #f5f5f5;
  }
}
.sort-order-footer {
  border-top: 1px solid #ccc;
}

.td-no-wrap {
  white-space: nowrap;
  vertical-align: top;
}

.legend_no_hover {
  > li {
    color: #666 !important;
  }
}

.grommetux-header {
  overflow: hidden;
}

.grommetux-meter__value-container,
.grommetux-meter__graphic-container,
.grommetux-meter__graphic {
  max-width: 100%;
}

.fixed-charges-editor {
  overflow: hidden !important;
}

.select-wide-box .grommetux-select {
  color: green;
  width: 100%;
}

.list {
  min-width: 200px;
}

.list-item {
  background-color: rgba(248, 248, 248, 0.95);
  padding: 6px 24px;
  font-size: 16px;
  font-weight: 100;
  line-height: 1.375;
  color: #000;
  min-height: 36px;
}
.list-item.selected {
  background-color: #d0f3eb;
}
.list-item:hover {
  background-color: rgba(221, 221, 221, 0.5);
}

.blade-on-estimated {
  background-color: #4a9e7c;
  text-align: end;
  font-weight: bold;
  padding-right: 4px;
}

.blade-on {
  background-color: #4abc8e;
  text-align: end;
  font-weight: bold;
  padding-right: 4px;
}

.blade-off {
  background-color: lightgray;
  text-align: end;
  font-weight: bold;
  padding-right: 4px;
}

.blade-off-estimated {
  background-color: rgb(191, 188, 188);
  text-align: end;
  font-weight: bold;
  padding-right: 4px;
}

.consumed-cell-estimated {
  background-color: rgb(231, 231, 231)
}

.narrow-table {
  .grommetux-button__icon {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  table > tbody > tr > td {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.rate-card-revision {
  background-color: white;
  transition: all 10s;
  .edited {
    background-color: #d0f3eb;
    transition: all 20s;
  }
}

.hpeFCLoginFormClass {
  h1 {
    font-size: 34px !important;
  }
  .grommetux-form-field:nth-child(2) {
    margin-top: 4px;
  }
  &.username-error {
    .grommetux-form-field:nth-child(1) {
      border-color: red;
    }
  }
  &.password-error {
    .grommetux-form-field:nth-child(2) {
      border-color: red;
    }
  }
}

.tenant-disconnection-heading {
  min-width: 400px;
  max-width: 430px;
  margin-top: 20px;
}

.tenant-disconnection-paragraph {
  max-width: 430px;
}

.tenant-disconnection-footer-container {
  position: absolute;
  bottom: 10px;
  width: 470px;
}

// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

// ***********************
//        Hacks
// ***********************

table.grommetux-table__mirror thead {
  z-index: 1;
}

// Remove border around login inputs
.grommetux-form-field input {
  border-color: transparent !important;
}

// Remove margin beneath "Flexible Capacity" in Sidebar
.grommetux-sidebar h3 {
  margin-bottom: 0;
}

// Reduce the size of inputs when we need them to be much smaller
.tiny-inputs {
  .grommetux-number-input {
    input {
      max-width: 50px;
    }
  }
  .grommetux-text-input {
    padding: 2px 4px;
    background-color: white;
    &:focus {
      outline: none !important;
      border-color: #2AD2C9;
    }
  }
}

.thinTableRow {
  padding: 2px 4px !important;
}

.veryThinTableHeaders {
  color: #444444;
  font-size: 14px;
  font-weight: 500;
}
.veryThinTableRow {
  padding: 0px 2px !important;
}
.rateTableRow {
  padding: 0px 0px !important;
}

// Region Chart Colors and Style
.grommetux-chart-graph--area.region-area {
animation: none;
}
.grommetux-color-index-region-installed {
fill: #fcdadc;
}
.grommetux-color-index-region-requested {
fill: #c4ffde;
}
.grommetux-color-index-region-committed {
fill: #fff3cd;
}
.grommetux-color-index-region-below {
fill: #c4e5ff;
}
.grommetux-chart-graph--line:not(.capacity-actual) {
stroke-width: 1px;
}
.grommetux-chart-graph--line.dashed {
stroke-dasharray: 5, 5;
&.grommetux-color-index-region-forecast {
  stroke: rgba(66,85,99,0.7);
}
}.grommetux-chart-graph--line.dashed-small {
stroke-dasharray: 2, 1;
&.grommetux-color-index-region-forecast {
  stroke: rgba(66,85,99,0.7);
}
}
.grommetux-legend__item-swatch.dashed {
outline: 1px solid rgba(66,85,99,0.7);
outline-offset: -1px;
&.grommetux-color-index-region-installed, &.grommetux-color-index-region-installed-legend {
  stroke: #fdeced;
}
&.grommetux-color-index-region-requested, &.grommetux-color-index-region-requested-legend {
  stroke: #c4ffde;
}
&.grommetux-color-index-region-committed, &.grommetux-color-index-region-committed-legend {
  stroke: #fff3cd;
}
&.grommetux-color-index-region-cold, &.grommetux-color-index-region-cold-legend {
  stroke: #c4e5ff;
}
}
.grommetux-legend.center-legend {
height: fit-content;
}
.hover-table {
margin-bottom: 0;
}
.region-chart-tooltip {
transform: translate(18px, -14px);
}
circle.dashed {
stroke-dasharray: 0, 0;
}

.ellipsis {
text-overflow: ellipsis;

/* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

span.wrap {
  word-wrap: break-word;
}

span.critical {
  // TODO provide correct color
}

#hpeFCLoginFormContainer img ~ h1 {
  font-size: 36px;
}

input::-ms-clear {display: none; width:0; height:0;}
header { overflow: hidden; }

.full-hack .grommetux-layer__container {
  height: 100vh;
}
.overflow-x-hidden {
  overflow-x: hidden;
}

.grommetux-box--flex {
  flex: 1 1 auto !important;
}

#hpe_privacy_banner_container {
  & + #content {
    .grommetux-box--full {
      height: calc(100vh - 74px);
    }
  }

}

.hide-number-arrows {
  -moz-appearance:textfield;
}

.hide-number-arrows::-webkit-inner-spin-button,
.hide-number-arrows::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

.invoice-error-message {
  padding-top: 120px;
  width: 100%;
  text-align: center;
  font-size: 24px;
}
.invoice-table td {
  padding: 0 8px;
}

.invoice-table td.drilldown-trigger {
  cursor: pointer;
  text-decoration: underline;
}
.invoice-table th {
  text-align: center;
}
.invoice-table td:not(:first-of-type) {
  text-align: right;
}
.invoice-table .invoice-units {
  text-align: left !important;
  padding-left: 0;
}
.invoice-table .invoice-header {
  background: #00b388;
}
.invoice-table .invoice-group {
  height: 32px;
}
.invoice-table .invoice-group {
  padding-left: 8px;
  background: #d4d0c8;
  font-weight: 600;
}
.invoice-table .invoice-category, .invoice-table .invoice-category-subtotal {
  height: 32px;
  font-weight: 600;
}
.invoice-table .invoice-category, .invoice-table .invoice-category-subtotal td:first-of-type {
  padding-left: 24px;
}
.invoice-table .invoice-category, .invoice-table .invoice-location-subtotal {
  height: 32px;
  font-weight: 600;
}

.invoice-table .invoice-category td:first-of-type {
  font-weight: 600;
  padding-left: 24px;
}
.invoice-table .invoice-proration td:first-of-type {
  font-size: 14px;
  padding-left: 64px;
}
.invoice-table .invoice-subgroup td:first-of-type {
  padding-left: 48px;
}
.invoice-table .invoice-subgroup {
  font-size: 16px;
}
.invoice-table .invoice-detail {
  font-size: 14px;
}
.invoice-table .invoice-detail.invoice-detail-step td {
  border-top: 1px solid #ccc;
}
.invoice-table .invoice-detail td:first-of-type {
  border-left: 64px solid white;
}
.invoice-table .invoice-detail.invoice-detail-subgroupTotal {
  background: #ecedf0;
}
.invoice-table .invoice-detail.invoice-detail-subgroupTotal td {
  border-bottom: 8px solid white;
}
.invoice-table .invoice-detail.invoice-detail-subgroupTotal td:first-of-type {
  font-weight: 600;
  border-left: 48px solid white;
}

.invoice-table .invoice-detail.invoice-detail-subProratedTotal {
  background: #f6f9f9;
}
.invoice-table .invoice-detail.invoice-detail-subProratedTotal td {
  border-bottom: 8px solid white;
}
.invoice-table .invoice-detail.invoice-detail-subProratedTotal td:first-of-type {
  font-weight: 400;
  font-style: italic;
  border-left: 48px solid white;
}
.invoice-table .invoice-detail a {
  text-decoration: underline;
  cursor: pointer;
}
.invoice-table .invoice-total {
  background: #ecedf0;
  font-weight: 600;
}
.invoice-table sup {
  color: #666;
  font-size: 75% !important;
}
.invoice-summary-table {
  margin-bottom: 0px;
}
.invoice-summary-table .invoice-summary {
  background: #fff;
  vertical-align: middle;
  padding-left: 5px;
}
.invoice-summary-table .invoice-summary td {
  border-bottom: 1px solid #333;
}
.invoice-summary-table .invoice-summary .invoice-summary-label {
  font-weight: 600;
  width: 125px;
}
.invoice-summary-table .invoice-summary .invoice-contract {
  text-align: end;
}
.invoice-footnote-container {
  color: #000;
  font-size: 14px;
  padding-top: 16px;
}
.invoice-footnote-container .invoice-footnote-numbered {
  margin-bottom: 0px;
  font-size: 14px !important;
  color: #666 !important;
}
.invoice-footnote-container .invoice-footnote-numbered sup {
  margin-left: 4px;
  margin-right: 4px;
  min-width: 24px;
  width: 24px;
}
.invoice-footnote-container .invoice-footnote-legal {
  padding-top: 12px;
}
.draggable-header {
  height: 48px;
  padding: 8px;
}
.draggable-header.location {
  background: #d4d0c8;
}

.expandable {
  cursor: pointer;
}

.expandable:hover {
  background-color: rgb(241, 236, 226) !important;
}

.invoice-group-collapsed {
  margin-bottom: 3px;
}
